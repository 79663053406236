import useOrders from '@/api/hooks/useOrder'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import React from 'react'
import { CASE_STATUS_OBJ, TRIAGE_TAG_COLORS } from '../dashboard.constants'
import useEvents from '@/api/hooks/useEvents'
import { useNavigate } from 'react-router-dom'
import { convertMinutesToTimeFormat } from '@/utils/timeUtil'
import { IconLoader } from '@tabler/icons-react'

// interface Case {
//   id: string;
//   patientName: string;
//   age: number;
//   sex: string;
//   symptoms: string;
//   licensePlate?: string;
//   type?: string;
//   status: string;
//   eta?: string;
// }

const CaseCard: React.FC<any> = (caseProps) => {
  const { caseId , redirectToHistory} = caseProps
  const navigation = useNavigate();
  const { updateSelectedView } = useOrders()
  const { updateGlobalReadForOrder } = useEvents()

  const handleOrderSelect = (orderId: string) => {
    if(redirectToHistory) {
      navigation(`/dashboard/case-history/${caseId}`);
    }
    else {
      updateSelectedView(orderId);
      updateGlobalReadForOrder(orderId, false);
    }
  }

let position = caseProps?.tripTracking?.initialEta ?  `${95 - Math.floor((caseProps?.tripTracking?.currentEta / caseProps?.tripTracking?.initialEta)*100)}%` : '-5%';

if(Math.floor((caseProps?.tripTracking?.currentEta / caseProps?.tripTracking?.initialEta)*100) > 100) {
  position = '-8%';
}

if(caseProps?.overallStatus === 'completed') {
  position = '95%';
}

const getCurrentEta = (eta: number) => {
  return convertMinutesToTimeFormat(Math.ceil(eta/60));
}



  return (
    <div
      className='mb-4 cursor-pointer rounded-xl shadow h-44'
      onClick={() => handleOrderSelect(caseId as string)}
    >
      <div className='flex justify-center rounded-t-xl bg-[#F7F7F7] p-2'>
        <div className=''>
          Case ID:  <span className='font-semibold text-black'>{caseId}</span>
        </div>
      </div>
      <div className='flex w-full flex-row justify-between p-5'>
        <div className='w-1/2 px-2 '>
          <div className='flex justify-between'>
            <div className='mb-2'>
              <div className='text-sm text-[#5B6169] mb-1'>Patient Name</div>
              <div className='text-xs font-semibold'>
                {caseProps?.patientDetails?.name}
              </div>
            </div>
            {caseProps?.triageTag && <div className='mb-2 flex items-center justify-center flex-col'>
              <div className='text-sm text-[#5B6169] mb-1'>Triage tag</div>
              <div className={cn('text-xs font-semibold px-3 py-1 text-white rounded-2xl', TRIAGE_TAG_COLORS?.[(caseProps.triageTag as string).toLowerCase()] && TRIAGE_TAG_COLORS?.[(caseProps.triageTag as string).toLowerCase()])}>
                {caseProps?.triageTag}
              </div>
            </div>}
            <div className='mb-2'>
              <div className='text-sm text-[#5B6169] mb-1'>Age / Sex</div>
              <div className='text-xs font-semibold capitalize'>
                {caseProps?.patientDetails?.age} Years / 
                 {' '}{(caseProps?.patientDetails?.gender as string)}
              </div>
            </div>
          </div>
          {/* <Separator className='bg-black'/> */}
          <div className='mb-2'>
            <div className='text-sm  text-[#5B6169] mb-1'>Medical symptoms</div>
            <div className='text-xs font-semibold'>
              {caseProps?.patientDetails?.medicalIssue?.reason?.toString()}
            </div>
          </div>
        </div>
        <Separator orientation='vertical' className='mx-4 bg-[#D9D9D9] h-24' />
        <div className='flex w-1/2 flex-col justify-between px-2'>
          <div className='flex w-full justify-between text-xs items-center'>
            {
              <div className='mb-2'>
                <div className='text-sm text-[#5B6169] mb-1'>License Plate</div>
                <div className='font-semibold'>
                  {caseProps?.assignmentDetails?.fleetDetails?.ambNumberPlate}
                </div>
              </div>
            }
            {
              <div className={
                cn('mb-2 flex flex-col',
                  caseProps?.assignmentDetails?.fleetDetails?.vehicleType && 'items-center justify-center'
               )}>
                <div className='text-sm text-[#5B6169] mb-1'>Type</div>
                <div className='font-semibold'>
                  {caseProps?.assignmentDetails?.fleetDetails?.vehicleType && (caseProps?.assignmentDetails?.fleetDetails?.vehicleType as string).toUpperCase()}
                  <span className='text-[#FB6514]'>{caseProps?.type !== 'non_5g'  && caseProps?.type !==null ? `-${caseProps?.type}`.toUpperCase(): ''}</span>
                </div>
              </div>
            }
            <div className='mb-2 flex items-end justify-center flex-col'>
              <div className='text-sm text-[#5B6169] mb-1 mx-1'>Status</div>
              <div className={cn('rounded-2xl bg-gray-300 text-xs px-3 py-1 font-medium text-black', 
                `${CASE_STATUS_OBJ?.[caseProps?.overallStatus]?.color}`,
                `${CASE_STATUS_OBJ?.[caseProps?.overallStatus]?.bgColor}`
              )}>
                {CASE_STATUS_OBJ?.[caseProps?.overallStatus]?.title ?? caseProps?.overallStatus}
              </div>
            </div>
          </div>
          <div className='flex-end flex w-full items-center justify-between'>
            <div className='flex h-px w-3/5 items-center bg-black'>
              <div className='h-2 w-2 bg-black'></div>
              <div className='relative left-[98%] h-2 w-2 rounded bg-black'></div>
              <div className={cn('relative left-[5%]', caseProps?.overallStatus !== 'completed' && 'animate-pulse')} style={{left: position}}>
                <img src='/images/amb-round.svg' />
              </div>
            </div>
            {
              <div className='mb-2 flex items-end justify-center flex-col'>
                <div className='text-[#5B6169] text-sm'>{caseProps?.overallStatus === 'dispatched' ?  'Time to pick-up' : 'Time to drop-off'}</div>
                <div className='text-lg font-bold'>{caseProps?.tripTracking?.currentEta && caseProps?.overallStatus !== 'completed' ? getCurrentEta(caseProps?.tripTracking?.currentEta): caseProps?.overallStatus !== 'completed' ? <IconLoader className='animate-spin' /> : '--' }</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const Dashboard: React.FC<any> = ({ orderData, currentTab , redirectToHistory}: any) => {
  return (
    <div className='p-3'>
      { orderData?.length === 0 ? (
        <div className='flex justify-center items-center align-center pt-10'>
         {currentTab === 'upcoming' ? 
          (<img src='/assets/webp/no-ongoing-case.svg' />)
          : (<img src='/assets/webp/no-complete-case.svg' /> )
        }
        </div>
      ) :
        (<>
          {orderData.map((caseData: any) => (
            <CaseCard key={caseData.caseId} {...caseData} redirectToHistory={redirectToHistory}/>
          ))}
        </>
        )
      }
    </div>
  )
}

export default Dashboard
